import request from '@/utils/request'
//我的成长信息
export function levelInfo(data){
    return request({
      url:'/web/grow/myGrowInfo',
      method:'post',
      data
    })
  }
//任务列表
export function taskList(data){
  return request({
    url:'/web/grow/growTask',
    method:'post',
    data
  })
}
//我的特权信息
export function myPrivilege(data){
  return request({
    url:'/web/grow/growPowerList',
    method:'post',
    data
  })
}
//领取成长任务红包
export function getTaskPrize(data){
  return request({
    url:'/web/grow/getTaskPrize',
    method:'post',
    data
  })
}
//可提现列表
export function getWithdrawList(data){
  return request({
    url:'/web/grow/withdrawList',
    method:'post',
    data
  })
}
//提现记录
export function getWithdrawRecord(data){
  return request({
    url:'/web/grow/flowerExchangeRecord',
    method:'post',
    data
  })
}
//点击邀请任务红包
export function clickRedBag(data){
  return request({
    url:'/web/grow/growRedPacket',
    method:'post',
    data
  })
}