<template>
  <div style="background-color: #171726;min-height: 100vh;overflow: scroll;">
    <div class="title_top">
      <nav-bar :icon="'img/backw.png'" :textColor="'#ffffff'" :backColor="'#171726'" />
      <div class="padding-lr-sm margin-bottom-xl">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="fetchData">
          <div class="drawLog_item display_flex justify-content_flex-justify align-items_center"
            v-for="(item, index) in listData" :key="index">
            <div class="drawLog_red display_flex align-items_center">
              <img src="img/small_red_bag.png" alt="">
              <div class="margin-left-xs">
                <p>{{ item.money }}</p>
                <span>{{ item.created_at }}</span>
              </div>
            </div>
            <div class="drawLog_right">
              <div class="display_flex align-items_center" style="justify-content: flex-end;">
                <p>-</p>
                <img src="img/hua_icon.png" alt="">
                <p>{{ item.flower }}</p>
              </div>
              <div class="display_flex align-items_center" style="margin-top: 5px;">
                <p>剩余:</p>
                <img src="img/hua_icon.png" alt="">
                <p>{{ item.remain_flower }}</p>
              </div>
            </div>

          </div>
        </van-list>
        <div class="noData margin-top-xl" v-if="noData">
          <img src="img/noData.png" alt="">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import { getWithdrawRecord } from "@/api/levelUp";
import NavBar from '@/components/navBar.vue';
export default {
  name: 'record',
  components: {
    NavBar
  },
  data() {
    return {
      listData: [],
      loading: false,
      finished: false,
      page: 1,
      rows: 15,
      noData: false
    };
  },
  //   created() {
  //     this.fetchData()
  //   },
  methods: {
    fetchData() {
      getWithdrawRecord().then(response => {
        this.listData = response.data
        this.loading = false;

        // 数据全部加载完成
          this.finished = true;
        
        if (this.listData.length == 0) {
          this.noData = true;
        }
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    whyNo(remark) {
      this.$toast.fail(remark)
    }

  },
};
</script>
<style>
.van-toast__text {
  white-space: normal;
  word-break: break-all;
}
</style>